import { Span } from "@components/ui/typograhpy/Span"
import React, { FC } from "react"
import { TasksListDialogStatus } from "."

type SelectStateProps = {
  handleChange: (value: TasksListDialogStatus) => void
  value: TasksListDialogStatus
}

export const SelectState: FC<SelectStateProps> = ({ handleChange, value }) => {
  return (
    <div className="mb-6 flex w-full border-b border-border">
      <button
        className="relative mr-6 text-left text-shade-500 transition-[color] duration-200 hover:text-white disabled:text-white"
        disabled={value === "enhancements"}
        onClick={() => handleChange("enhancements")}
      >
        <Span className="mb-3 block" variant="button-label-big">
          Enhancements
          {value === "enhancements" && (
            <span className="absolute -bottom-[1px] block h-[1px] w-full bg-white" />
          )}
        </Span>
      </button>
      <button
        className="relative mr-6 text-left text-shade-500 transition-[color] duration-200 hover:text-white disabled:text-white"
        disabled={value === "uploads"}
        onClick={() => handleChange("uploads")}
      >
        <Span className="mb-3 block" variant="button-label-big">
          Uploads
          {value === "uploads" && (
            <span className="absolute -bottom-[1px] block h-[1px] w-full bg-white" />
          )}
        </Span>
      </button>
    </div>
  )
}
