import { cn } from "@lib/cn"
import { useDispatch, useToasts } from "@lib/store"
import { AnimatePresence } from "framer-motion"
import { useSession } from "next-auth/react"
import { useEffect, useRef, useState } from "react"
import { TasksListDialog } from "./app/task-list/dialog"
import { Toast, ToastActionVariant } from "./toast"

type View = "compact" | "expanded"

export const Toasts = () => {
  const [taskListStatus, setTaskListStatus] = useState<
    "processing" | "closed" | "completed"
  >("closed")
  const ref = useRef<HTMLDivElement>(null)
  const [view, setView] = useState<View>("compact")
  const { byId, items } = useToasts()
  const { status } = useSession()
  const dispatch = useDispatch()
  const reversed = [...byId].reverse()

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (ref.current && ref.current.contains(event.target as Node)) {
        handleChangeView("expanded")
      } else {
        handleChangeView("compact")
      }
      return true
    }
    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  })

  return (
    <div
      className={cn(
        "absolute bottom-10 left-1/2 -translate-x-1/2 flex-col justify-center",
        "z-[100] flex w-[calc(100%-2rem)] max-w-[28.25rem]",
      )}
    >
      <div
        className={cn("group/toasts relative")}
        ref={ref}
        data-view={view}
        onMouseEnter={() => handleChangeView("expanded")}
        onMouseLeave={() => handleChangeView("compact")}
      >
        <AnimatePresence>
          {reversed.map((id, key) => {
            const toast = items[id]
            return (
              <Toast
                key={id}
                {...toast}
                isFirstVisible={key === 0}
                index={key}
                handleRequestClose={() => {
                  dispatch({ type: "clear-toast", id: id })
                }}
                handleClickButton={() =>
                  handleClickButton(toast.buttonAction, id)
                }
                isWrapperExpanded={view === "expanded"}
              />
            )
          })}
        </AnimatePresence>
      </div>
      {status === "authenticated" && (
        <>
          <TasksListDialog
            open={["processing", "completed"].includes(taskListStatus)}
            initialStatus={
              taskListStatus === "completed" ? "enhancements" : "uploads"
            }
            onOpenChange={() => setTaskListStatus("closed")}
          />
        </>
      )}
    </div>
  )

  function handleChangeView(newView: View) {
    if (newView === "expanded" && byId.length > 1) {
      setView("expanded")
    } else {
      setView("compact")
    }
  }

  function handleClickButton(
    actionType: ToastActionVariant | undefined,
    id: string,
  ) {
    if (actionType === "progress-modal") {
      setTaskListStatus("processing")
    }
    if (actionType === "progress-modal-errors") {
      setTaskListStatus("completed")
    }
    dispatch({ type: "clear-toast", id })
  }
}
