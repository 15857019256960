import {
  EnhancedImageFieldsFragment,
  EnhancedImageParameters,
  EnhancedImageParametersArtifactsDetectionRemoval,
  EnhancedImageParametersDefaultImageEnhancement,
  EnhancedImageParametersPortraitBackgroundMode,
  EnhancedImageParametersUpscalingFactor,
} from "@generated"

const backgroundRemovalModeLabels = {
  [EnhancedImageParametersPortraitBackgroundMode.Remove]: "Removal",
  [EnhancedImageParametersPortraitBackgroundMode.Replace]: "Replacement",
}

const _getDescriptionForBackgroundEnhancement = (
  parameters: EnhancedImageParameters,
): string => {
  const enhancements: string[] = []
  if (
    parameters.backgroundRemovalMode &&
    parameters.backgroundRemovalMode !==
      EnhancedImageParametersPortraitBackgroundMode.Original
  ) {
    enhancements.push(
      backgroundRemovalModeLabels[parameters.backgroundRemovalMode],
    )
  }

  if (
    parameters.backgroundRemovalBlur &&
    parameters.backgroundRemovalMode !==
      EnhancedImageParametersPortraitBackgroundMode.Remove
  ) {
    enhancements.push("Blur")
  }

  return enhancements.join(" and ")
}

export const getEnhancementDescription = (
  parameters: EnhancedImageParameters,
  workflow?: EnhancedImageFieldsFragment["Workflow"],
): string => {
  let description: string[] = []
  if (workflow) {
    return `Workflow: ${workflow.name}`
  }
  if (parameters.superResolution) {
    description.push(
      `AI Upscaling: ${parameters.superResolution.toLowerCase()}`,
    )
  }
  if (parameters.artifactsDetectionRemoval) {
    if (
      parameters.artifactsDetectionRemoval ===
      EnhancedImageParametersArtifactsDetectionRemoval["Auto"]
    ) {
      description.push(`AI Artifacts Detection: Auto`)
    }
    if (
      parameters.artifactsDetectionRemoval ===
      EnhancedImageParametersArtifactsDetectionRemoval["On"]
    ) {
      description.push(`AI Artifacts Detection: Enforced`)
    }
  }
  if (
    parameters.defaultImageEnhancement ===
    EnhancedImageParametersDefaultImageEnhancement["On"]
  )
    description.push(`Default image enhancement: Enabled`)

  if (parameters.upscalingCustomWidth) {
    description.push(`AI Upscaling: ${parameters.upscalingCustomWidth}px wide`)
  }
  if (
    parameters.upscalingFactor &&
    parameters.upscalingFactor !==
      EnhancedImageParametersUpscalingFactor["Custom"]
  ) {
    description.push(`AI Upscaling: ${parameters.upscalingFactor}`)
  }
  if (parameters.preset) {
    description.push(`Preset: ${parameters.preset}`)
  }

  const backgroundEnhancementDescription =
    _getDescriptionForBackgroundEnhancement(parameters)
  if (backgroundEnhancementDescription) {
    description.push(`Background: ${backgroundEnhancementDescription}`)
  }

  return description.join("<br />")
}
