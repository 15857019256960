import { Text } from "@components/ui/typograhpy/Text"
import React, { VFC } from "react"

type TaskListDialogHeadingProps = {
  photoCount: number
  pdfCount: number
}

export const TaskListDialogHeading: VFC<TaskListDialogHeadingProps> = ({
  photoCount,
  pdfCount,
}) => {
  if (!photoCount && !pdfCount) {
    return (
      <Text className="mb-2 text-shade-400" variant="regular">
        Nothing to see here!
      </Text>
    )
  }

  const photoCountText = photoCount
    ? `${photoCount} ${photoCount === 1 ? "photo" : "photos"}`
    : ""
  const pdfCountText = pdfCount
    ? `${pdfCount} ${pdfCount === 1 ? "pdf" : "pdfs"}`
    : ""
  return (
    <Text className="mb-2 text-shade-400" variant="micro">
      {photoCountText}
      {`${photoCount && pdfCount ? " and " : ""}`}
      {pdfCountText}
    </Text>
  )
}
