import { UploadFieldsFragment } from "@generated"
import { getGraphqlClient } from "@lib/graphql-client"
import { useQuery } from "@tanstack/react-query"

const { sdk } = getGraphqlClient()

export type Upload = UploadFieldsFragment

export function useUpload(uploadId: string): {
  upload: Upload | null
  refetch: () => void
} {
  const { data, error, refetch } = useQuery<Upload>({
    queryKey: ["upload", uploadId],
    queryFn: async () => {
      const result = await sdk.Upload({ id: uploadId })
      return result.upload as Upload
    },
    // This is very important, it will make sure that the query is never refetched (i.e. on focus or rerender)
    // That could cause the status property being overwritten on a rerender
    staleTime: Infinity,
    enabled: !!uploadId,
  })

  if (process.env.NODE_ENV === "development" && error) {
    console.error(error)
  }
  return {
    upload: data ?? null,
    refetch,
  }
}
