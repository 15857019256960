import { Text } from "@components/ui/typograhpy/Text"
import { NewUploadTask } from "@lib/store/uploads"
import React, { FC } from "react"
import { StatusFinished } from "./StatusFinished"

type UploadStatusIndicatorProps = {
  status: NewUploadTask["status"]
  uploadProgress: number
}

export const UploadStatusIndicator: FC<UploadStatusIndicatorProps> = ({
  status,
  uploadProgress,
}) => {
  if (status === "uploading" || status === "upload-pending") {
    return (
      <Text variant="micro" className="text-shade-400">
        Uploading {uploadProgress}%
      </Text>
    )
  }
  if (status === "finished") {
    return <StatusFinished>Uploaded</StatusFinished>
  }

  return null
}
