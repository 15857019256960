import { getEnhancementDescription } from "@components/app/task-list/helpers/get-enhancement-description"
import { Box } from "@components/Box"
import { Text } from "@components/ui/typograhpy/Text"
import { EnhancedImageFieldsFragment, EnhancedImageParameters, EnhancedImageStatus, } from "@generated"
import { cn } from "@lib/cn"
import { useUpload } from "@lib/hooks/use-upload"
import { imgix } from "@lib/imgix"
import { parseError } from "@lib/parse-error"
import NextImage from "next/image"
import { FC } from "react"
import { EnhancementStatusIndicator } from "./EnhancementStatusIndicator"
import { NoImage } from "./NoImage"

export const ListItemEnhancement: FC<EnhancedImageFieldsFragment> = ({
  status,
  errorCode,
  EnhancedImageParameters,
  Workflow,
  uploadId,
}) => {
  const enhancementDescription = getEnhancementDescription(
    EnhancedImageParameters as EnhancedImageParameters,
    Workflow,
  )
  const { upload } = useUpload(uploadId)
  if (!upload) {
    return null
  }
  return (
    <li className="grid items-center mb-5 grid-cols-[40px_auto_7rem] gap-x-4">
      {upload.url ? (
        <div
          className={cn(
            "relative rounded-md w-10 h-10 overflow-hidden after:left-0 after:absolute after:content-[''] after:w-full after:h-full after:bg-background after:bg-opacity-40",
            "data-[is-ready]:after:opacity-0"
          )}
          data-is-ready={
            status === EnhancedImageStatus["Finished"] ? "" : undefined
          }
        >
          <NextImage
            src={imgix(upload?.url, { width: 100 })}
            alt=""
            width={40}
            height={40}
            objectFit="cover"
            objectPosition="cover"
          />
        </div>
      ) : (
        <NoImage />
      )}
      <div className="w-full overflow-hidden">
        <Text
          variant="regular"
          className={cn("w-full whitespace-nowrap overflow-hidden mb-0", status === EnhancedImageStatus["Processing"] ||
          status === EnhancedImageStatus["Queued"]
            ? "text-shade-400"
            : "text-white")}
        >
          {upload?.originalFilename}
        </Text>
        {status === EnhancedImageStatus["Error"] ? (
          <Text variant="small" className="text-danger">
            {parseError(errorCode)}
          </Text>
        ) : (
          <Text
            variant="micro"
            className="text-grey"
            dangerouslySetInnerHTML={{ __html: enhancementDescription }}
          />
        )}
      </div>
      <Box className="mr-2 flex justify-end">
        <EnhancementStatusIndicator status={status} uploadProgress={100} />
      </Box>
    </li>
  )
}
