import { Box } from "@components/Box"
import { Text } from "@components/ui/typograhpy/Text"
import { parseError } from "@lib/parse-error"
import { NewUploadTask } from "@lib/store/uploads"
import NextImage from "next/image"
import React, { FC, useEffect, useState } from "react"
import { NoImage } from "./NoImage"
import { UploadStatusIndicator } from "./UploadStatusIndicator"
import { cn } from "@lib/cn"

export const ListItemUpload: FC<NewUploadTask> = ({
  status,
  errorCode,
  uploadProgress,
  file,
  ...rest
}) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  useEffect(() => {
    async function createThumbnail() {
      setThumbnail(await fileToThumbnail(file))
    }
    if (file) {
      createThumbnail()
    }
  }, [file])
  return (
    <li className="grid items-center mb-5 grid-cols-[40px_auto_7rem] gap-x-4">
      {thumbnail ? (
        <div
          className={cn(
            "relative rounded-md w-10 h-10 overflow-hidden after:left-0 after:absolute after:content-[''] after:w-full after:h-full after:bg-background after:bg-opacity-40",
            "data-[is-ready]:after:opacity-0"
          )}
          data-is-ready={status === "finished" ? "" : undefined}
        >
          <NextImage
            src={thumbnail}
            alt=""
            width={40}
            height={40}
            objectFit="cover"
            objectPosition="cover"
          />
        </div>
      ) : (
        <NoImage />
      )}
      <div className="w-full overflow-hidden">
        <Text variant="regular" className="w-full whitespace-nowrap overflow-hidden mb-0 text-white">
          {file.name}
        </Text>
        {status === "error" ? (
          <Text variant="small" className="text-danger">
            {parseError(errorCode)}
          </Text>
        ) : (
          <Text variant="micro" className="text-grey">
            Upload
          </Text>
        )}
      </div>
      <Box className="mr-2 flex justify-end">
        <UploadStatusIndicator
          status={status}
          uploadProgress={uploadProgress}
        />
      </Box>
    </li>
  )
}

async function fileToThumbnail(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement("canvas")
      const { width, height } = img
      const ratio = height / width
      const newWidth = 75
      const newHeight = Math.round(newWidth * ratio)
      canvas.width = newWidth
      canvas.height = newHeight
      const ctx = canvas.getContext("2d")
      if (ctx) {
        ctx.drawImage(img, 0, 0, newWidth, newHeight)
      }
      resolve(canvas.toDataURL())
    }

    reader.onload = (e) => {
      if (e.target && e.target.result) {
        return (img.src = e.target.result.toString())
      }
    }
    reader.readAsDataURL(file)
  })
}
