import { ModalOrSidebar } from "@components/ui/modal-sidebar"
import { EnhancedImageStatus } from "@generated"
import { useTasksList } from "@lib/hooks/use-tasks-list"
import { useStore } from "@lib/store"
import { FC, useEffect, useMemo, useState } from "react"
import { TaskListDialogHeading } from "./Heading"
import { ListItemEnhancement } from "./ListItemEnhancement"
import { ListItemUpload } from "./ListItemUpload"
import { SelectState } from "./SelectState"

type TasksListDialogProps = {
  open: boolean
  initialStatus?: TasksListDialogStatus
  onOpenChange: (open: boolean) => void
}

export type TasksListDialogStatus = "uploads" | "enhancements"

export const TasksListDialog: FC<TasksListDialogProps> = ({
  open,
  onOpenChange,
  initialStatus = "enhancements",
}) => {
  const [status, setStatus] = useState<TasksListDialogStatus>(initialStatus)
  const uploads = useStore((state) =>
    state.uploads.byId.map((id) => state.uploads.items[id]),
  )
  const { completed, inProgress } = useTasksList()
  const queued = inProgress.filter(
    (item) => item.status === EnhancedImageStatus.Queued,
  )
  const processing = inProgress.filter(
    (item) => item.status === EnhancedImageStatus.Processing,
  )

  // Filter out the enhancements that are not in FULL mode
  // currently we only have FULL or PREVIEW mode, but we might have more in the future
  // this filter prevents PREVIEW enhancements from showing up in the list
  const enhancements = [...processing, ...queued, ...completed].filter(
    (item) => item.mode === "FULL",
  )

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus])

  const count = useMemo(() => {
    if (status === "enhancements") {
      return {
        photoCount: enhancements.filter(
          (item) => item.Upload?.mimetype !== "application/pdf",
        ).length,
        pdfCount: enhancements.filter(
          (item) => item.Upload?.mimetype === "application/pdf",
        ).length,
      }
    }

    return {
      photoCount: uploads.filter(
        (item) =>
          item.file?.type !== "application/pdf" &&
          item.upload?.mimetype !== "application/pdf",
      ).length,
      pdfCount: uploads.filter(
        (item) =>
          item.file?.type === "application/pdf" ||
          item.upload?.mimetype === "application/pdf",
      ).length,
    }
  }, [status, enhancements, uploads])

  return (
    <ModalOrSidebar title="Processing" open={open} onOpenChange={onOpenChange}>
      <SelectState handleChange={(val) => setStatus(val)} value={status} />
      <ul className="relative overflow-y-auto data-[extra-padding-bottom]:pb-20 data-[extra-padding-bottom]:md:pb-16">
        <TaskListDialogHeading {...count} />
        {status === "enhancements" &&
          enhancements.map((item) =>
            item ? (
              <ListItemEnhancement key={item.id} {...(item as any)} />
            ) : null,
          )}
        {status === "uploads" &&
          uploads.map((item) => <ListItemUpload key={item.id} {...item} />)}
      </ul>
    </ModalOrSidebar>
  )
}
