import { EnhancedImageFieldsFragment } from "@generated"
import { getGraphqlClient } from "@lib/graphql-client"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"

const { sdk } = getGraphqlClient()
export function useTasksList() {
  const { data } = useQuery({
    queryKey: ["tasks-list"],
    queryFn: () => sdk.TasksList(),
  })
  const client = useQueryClient()

  const hash = data?.tasksList?.hash
  const { inProgress, completed } = useMemo(() => {
    const inProgress =
      (data?.tasksList?.inProgress as EnhancedImageFieldsFragment[]) ?? []
    const completed =
      (data?.tasksList?.completed as EnhancedImageFieldsFragment[]) ?? []

    for (const item of completed) {
      client.invalidateQueries({ queryKey: ["upload", item.uploadId] })
    }

    return { inProgress, completed }
    // The server will return a hash of the tasks list, with we can use to detect if something changed
  }, [hash]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    inProgress,
    completed,
  }
}
