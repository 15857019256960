import { Box } from "@components/Box"
import IconDanger from "@components/ui/icons/IconDanger"
import { LoadingPulse } from "@components/ui/LoadingPulse"
import { Text } from "@components/ui/typograhpy/Text"
import { EnhancedImageStatus } from "@generated"
import React, { FC } from "react"
import { StatusFinished } from "./StatusFinished"

type EnhancementStatusIndicatorProps = {
  status: EnhancedImageStatus
  uploadProgress: number
}

export const EnhancementStatusIndicator: FC<
  EnhancementStatusIndicatorProps
> = ({ status, uploadProgress }) => {
  if (status === EnhancedImageStatus["Error"]) {
    return (
      <Box className="text-danger">
        <IconDanger size={20} />
      </Box>
    )
  }

  if (status === EnhancedImageStatus["Finished"]) {
    return <StatusFinished>Enhanced</StatusFinished>
  }

  if (status === EnhancedImageStatus["Queued"]) {
    return (
      <Text variant="micro" className="text-shade-400">
        Waiting
      </Text>
    )
  }

  if (status === EnhancedImageStatus["Processing"]) {
    return (
      <Box className="flex items-center text-white">
        <Text variant="micro" className="text-white mr-2">
          Enhancing
        </Text>
        <LoadingPulse />
      </Box>
    )
  }

  return null
}
