import IconFile from "@components/ui/icons/IconFile"
import React, { FC } from "react"

export const NoImage: FC<{ children?: never }> = () => {
  return (
    <div className="bg-opacity flex justify-center items-center rounded-md w-10 h-10">
      <IconFile size={24} />
    </div>
  )
}
