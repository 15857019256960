import IconCheck from "@components/ui/icons/IconCheck"
import { Text } from "@components/ui/typograhpy/Text"
import React, { FC } from "react"

type StatusFinishedProps = {
  children: string
}

export const StatusFinished: FC<StatusFinishedProps> = ({ children }) => {
  return (
    <Text variant="micro" className="flex items-center text-success">
      <IconCheck size={12} className="mr-1 text-inherit" />
      {children}
    </Text>
  )
}
